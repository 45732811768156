import { StyleSheet, View } from '@react-pdf/renderer'
import type { Item } from '@shared/types-v0.1.0'
import { type Scheme, defaultStyles } from '../node/definition'
import Cell from './Cell'
import { combineStyles } from './utils'

export const Row = (p: { scheme?: ReturnType<Scheme>; item: Item; idx: number }) => {
	const { scheme, item, idx } = p

	const row = scheme?.row?.(item, idx)
	const tableScheme = row?.table

	const styles = StyleSheet.create({
		row: combineStyles(defaultStyles.row, row?.styles?.row),
		header: combineStyles(defaultStyles.header, row?.styles?.header),
		data: combineStyles(defaultStyles.data, row?.styles?.data),
		table: combineStyles(defaultStyles.table, tableScheme?.style),
	})

	const headerCells = row?.headerCells?.(item)
	const HeaderCells = () =>
		headerCells?.length ? (
			<View style={styles.header}>
				{headerCells.map((c) => (
					<Cell key={c.text} cell={c} />
				))}
			</View>
		) : null

	const dataCells = row?.dataCells?.(item)
	const DataCells = () =>
		dataCells?.length ? (
			<View style={styles.data}>
				{dataCells.map((c) => (
					<Cell key={c.text} cell={c} />
				))}
			</View>
		) : null

	const Table = () =>
		tableScheme?.items?.length ? (
			<View style={styles.table} wrap={!(tableScheme?.wrap === false)} fixed={tableScheme?.fixed}>
				{tableScheme.items.map((c, idx) => (
					<Row key={c.id} scheme={tableScheme} item={c} idx={idx} />
				))}
			</View>
		) : null

	if (row)
		return (
			<View style={styles.row}>
				<HeaderCells />
				<DataCells />
				<Table />
			</View>
		)
	return null
}
