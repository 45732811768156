import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'

export type Props = BaseReactProps & { src: string }

import Comp from '../component'

export default {
	hashTag: '#pre-release',
	module: { static: Comp },
	inNode: {
		inputs: [
			getPortDef({
				name: 'src',
				displayName: 'Image source',
				group: 'Params',
				type: 'string',
				validate: (p: Props) => Boolean(p.src),
			}),
			getPortDef({
				name: 'styles',
				displayName: 'Styles',
				group: 'Styles',
				type: 'objectEval',
				codeComment: '//() => { return { width: 240 } } // Здесь CSS от react-pdf, не Mantine',
			}),
			getPortDef({ name: 'debug', displayName: 'Debug', group: 'Advanced', type: 'boolean', default: false }),
		],
	},
	afterNode: {
		validate: async (p, model) => {
			const parentNodeName = model.parent?.type.split('.')[2]
			if (['PdfPage', 'PdfView'].includes(parentNodeName)) return true
			return `Parent of "PdfIamge" must be "PdfPage" or "PdfView", got "${parentNodeName}".`
		},
	},
} satisfies ReactNodeDef
