import { StyleSheet, View } from '@react-pdf/renderer'
import { forwardRef } from 'react'
import { type Props, defaultStyles } from '../node/definition'
import { Row } from './Row'
import { combineStyles } from './utils'

export default forwardRef((p: Props) => {
	const scheme = p.scheme?.(p.items || [])
	const styles = StyleSheet.create({ table: combineStyles(defaultStyles.table, scheme?.style) })
	const wrap = scheme?.wrap !== false

	return (
		<View wrap={wrap} fixed={scheme?.fixed} style={styles.table}>
			{p.items?.length && p.items.map((item, idx) => <Row key={item.id} scheme={scheme} item={item} idx={idx} />)}
		</View>
	)
})
