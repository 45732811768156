import { Page, StyleSheet } from '@react-pdf/renderer'
import { forwardRef } from 'react'
import type { Props } from '../node/definition'

const childrenNames = ['PdfView', 'PdfText', 'PdfImage']

export default forwardRef((p: Props, ref) => {
	const ch = p.children as any

	const children = Array.isArray(ch)
		? ch.filter((i) => childrenNames.includes(i.props.noodlNode?.model.type.split('.')[2]))
		: childrenNames.includes(ch?.props.noodlNode?.model.type.split('.')[2])
			? ch
			: undefined

	const styles = StyleSheet.create({ page: p.styles || {} })

	return (
		<Page {...p} {...p.customProps} style={styles.page}>
			{children}
		</Page>
	)
})
