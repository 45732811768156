import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'

export type Props = BaseReactProps

import Comp from '../component'

export default {
	hashTag: '#pre-release',
	module: { static: Comp },
	inNode: {
		inputs: [
			getPortDef({
				name: 'orientation',
				displayName: 'Orientation',
				group: 'Params',
				type: [
					{ value: 'portrait', label: 'Portrait' },
					{ value: 'landscape', label: 'Landscape' },
				],
				default: 'portrait',
			}),
			getPortDef({ name: 'wrap', displayName: 'Wrap', group: 'Params', type: 'boolean', default: false }),
			getPortDef({
				name: 'styles',
				displayName: 'Styles',
				group: 'Styles',
				type: 'objectEval',
				codeComment: `//() => { return { border: '2px solid red' } } // Здесь CSS от react-pdf, не Mantine`,
			}),
			getPortDef({ name: 'debug', displayName: 'Debug', group: 'Advanced', type: 'boolean', default: false }),
		],
	},
	afterNode: {
		validate: async (p, model) => {
			const parentNodeName = model.parent?.type.split('.')[2]
			if (['PdfDocument'].includes(parentNodeName)) return true
			return `Parent of "PdfPage" must be "PdfDocument", got "${parentNodeName}".`
		},
	},
} satisfies ReactNodeDef
