import { StyleSheet, Text } from '@react-pdf/renderer'
import { forwardRef } from 'react'
import type { Props } from '../node/definition'

export default forwardRef((p: Props, ref) => {
	const styles = StyleSheet.create({ text: { fontFamily: 'Roboto', ...(p.styles || {}) } })

	return (
		<Text {...p} {...p.customProps} style={styles.text}>
			{p.text}
		</Text>
	)
})
