import type { Styles } from '@react-pdf/renderer'
import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'
import type { Item } from '@shared/types-v0.1.0'

export type Props = BaseReactProps & {
	scheme?: Scheme
	items?: Item[]
}

export type Scheme = (items: Item[]) => {
	style?: Styles['string']
	wrap?: boolean
	fixed?: boolean
	items?: Item[]
	row?: (item: Item, idx: number) => RowType | undefined
}

export type RowType = {
	styles?: {
		row?: Styles['string']
		header?: Styles['string']
		data?: Styles['string']
	}
	headerCells?: (item: Item) => Cell[] | undefined
	dataCells?: (item: Item) => Cell[] | undefined
	table?: ReturnType<Scheme>
}

export type Cell = {
	type?: 'text' | 'link' | 'image'
	text?: string
	src?: string
	styles?: {
		cell?: Styles['string']
		text?: Styles['string']
		image?: Styles['string']
	}
}

export const defaultStyles = {
	table: {
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderBottomWidth: 1,
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
	},
	row: {},
	header: {
		flexDirection: 'row',
		fontWeight: 'bold',
	},
	data: {
		flexDirection: 'row',
		borderTop: 1,
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
	},
	cell: {
		borderLeftWidth: 1,
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
	},
	text: {
		fontFamily: 'Roboto',
		paddingHorizontal: 8,
		paddingVertical: 4,
	},
	image: {
		paddingHorizontal: 8,
		paddingVertical: 4,
	},
}

import Comp from '../component'

export default {
	hashTag: '#experimental',
	module: { static: Comp },
	inNode: {
		inputs: [
			getPortDef({ name: 'scheme', displayName: 'Scheme', group: 'Params', type: 'funcEval' }),
			getPortDef({ name: 'items', displayName: 'Items', group: 'Data', type: 'array' }),
		],
	},
	afterNode: {
		validate: async (p, model) => {
			const parentNodeName = model.parent?.type.split('.')[2]
			if (['PdfPage', 'PdfView'].includes(parentNodeName)) return true
			return `Parent of "PdfTable" must be "PdfPage" or "PdfView", got "${parentNodeName}".`
		},
	},
} satisfies ReactNodeDef
