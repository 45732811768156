import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'
import { lazy } from 'react'

export type Props = BaseReactProps & {
	currentPage?: number
	sourceUrl?: any
	pdfViewerWidth?: number
	pdfViewerHeight?: number
}

export default {
	hashTag: '#pre-release',
	module: { dynamic: lazy(() => import('../component')) },
	inNode: {
		inputs: [
			getPortDef({ name: 'sourceUrl', displayName: 'Source', group: 'Params', type: '*' }),
			getPortDef({
				name: 'currentPage',
				displayName: 'Current page',
				group: 'Params',
				type: 'number',
				default: 1,
				validate: (p: Props) => Boolean(p.currentPage),
			}),
			getPortDef({ name: 'pdfViewerWidth', displayName: 'Width', group: 'Dimensions', type: 'number' }),
			getPortDef({ name: 'pdfViewerHeight', displayName: 'Height', group: 'Dimensions', type: 'number' }),
			getPortDef({ name: 'nextPage', displayName: 'Next', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'previousPage', displayName: 'Previous', group: 'Signals', type: 'signal' }),
		],
		outputs: [
			getPortDef({ name: 'currentPage', displayName: 'Current page', group: 'Params', type: 'number' }),
			getPortDef({ name: 'totalPages', displayName: 'Total pages', group: 'Params', type: 'number' }),
		],
	},
} satisfies ReactNodeDef
