import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'

export type Props = BaseReactProps & {
	fonts?: {
		family: string
		fonts: {
			src: string
			fontStyle?: string
			fontWeight?: number | 'thin' | 'ultralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'ultrabold' | 'heavy'
			[key: string]: any
		}[]
	}[]
}

import Comp from '../component'

export default {
	hashTag: '#pre-release',
	module: { static: Comp },
	inNode: {
		inputs: [
			getPortDef({ name: 'fonts', displayName: 'Fonts', group: 'Styles', type: 'array' }),
			getPortDef({ name: 'create', displayName: 'Create', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'reset', displayName: 'Reset', group: 'Signals', type: 'signal' }),
		],
		outputs: [
			getPortDef({ name: 'blob', displayName: 'Blob', group: 'Data', type: '*' }),
			getPortDef({ name: 'creating', displayName: 'Creating', group: 'States', type: 'number' }),
			getPortDef({ name: 'created', displayName: 'Created', group: 'Signals', type: 'signal' }),
			getPortDef({ name: 'reseted', displayName: 'Reseted', group: 'Signals', type: 'signal' }),
		],
	},
} satisfies ReactNodeDef
