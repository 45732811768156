import { Image, Link, StyleSheet, Text, View } from '@react-pdf/renderer'
import { type Cell, defaultStyles } from '../node/definition'
import { combineStyles } from './utils'

export default (p: { cell: Cell }) => {
	const { type = 'text', styles, text, src } = p.cell

	const style = StyleSheet.create({
		cell: combineStyles(defaultStyles.cell, styles?.cell),
		text: combineStyles(defaultStyles.text, styles?.text),
		image: combineStyles(defaultStyles.image, styles?.image),
	})

	switch (type) {
		case 'text':
			return (
				<View style={style.cell}>
					<Text style={style.text}>{text}</Text>
				</View>
			)
		case 'image':
			return (
				<View style={style.cell}>
					<Image style={style.image} src={src} />
				</View>
			)
		case 'link':
			return (
				<View style={style.cell}>
					<Link style={style.text} href={src}>
						{text}
					</Link>
				</View>
			)
		default:
			return null
	}
}
