import type { BaseReactProps, ReactNodeDef } from '@shared/node-v1.0.0'
import { getPortDef } from '@shared/port-v1.0.0'

export type Props = BaseReactProps & { text?: string }

import Comp from '../component'

export default {
	hashTag: '#pre-release',
	module: { static: Comp },
	inNode: {
		inputs: [
			getPortDef({ name: 'text', displayName: 'Text', group: 'Params', type: 'string', multiline: true }),
			getPortDef({ name: 'wrap', displayName: 'Wrap', group: 'Params', type: 'boolean', default: true }),
			getPortDef({ name: 'fixed', displayName: 'Fixed', group: 'Params', type: 'boolean', default: false }),
			getPortDef({
				name: 'styles',
				displayName: 'Styles',
				group: 'Styles',
				type: 'objectEval',
				codeComment: `//() => { return { backgroundColor: 'lightgreen' } } // Здесь CSS от react-pdf, не Mantine`,
			}),
			getPortDef({ name: 'debug', displayName: 'Debug', group: 'Advanced', type: 'boolean', default: false }),
		],
	},
	afterNode: {
		validate: async (p, model) => {
			const parentNodeName = model.parent?.type.split('.')[2]
			if (['PdfPage', 'PdfView'].includes(parentNodeName)) return true
			return `Parent of "PdfText" must be "PdfPage" or "PdfView", got "${parentNodeName}".`
		},
	},
} satisfies ReactNodeDef
