const combineStyles = (main: any, override: any) => {
	const result = { ...main }
	const ov = { ...override }
	if (typeof override === 'object') {
		Object.assign(result, ov)
	}
	return result
}

export { combineStyles }
