import { set } from 'shared/src/libs/just'

import pdfDocument from './pdfDocument'
import pdfImage from './pdfImage'
import pdfPage from './pdfPage'
import pdfTable from './pdfTable'
import pdfText from './pdfText'
import pdfView from './pdfView'
import pdfViewer from './pdfViewer'

const reactPackages = [pdfViewer, pdfDocument, pdfPage, pdfView, pdfText, pdfImage, pdfTable]

set(
	window,
	['R', 'packages', 'pdf'],
	[
		{
			name: 'Rolder Kit - UI - PDF',
			type: '',
			subCategories: [
				{
					name: 'Viewer',
					items: ['rolder-kit.api-v1.PdfViewer'],
				},
				{
					name: 'Renderer',
					items: [
						'rolder-kit.api-v1.PdfDocument',
						'rolder-kit.api-v1.PdfPage',
						'rolder-kit.api-v1.PdfView',
						'rolder-kit.api-v1.PdfText',
						'rolder-kit.api-v1.PdfImage',
						'rolder-kit.api-v1.PdfTable',
					],
				},
			],
		},
	]
)

Noodl.defineModule({ name: 'pdf', reactNodes: reactPackages })
