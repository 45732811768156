import { StyleSheet, View } from '@react-pdf/renderer'
import { forwardRef } from 'react'
import type { Props } from '../node/definition'

export default forwardRef((p: Props, ref) => {
	const styles = StyleSheet.create({ view: p.styles || {} })

	return (
		<View {...p} {...p.customProps} style={styles.view}>
			{p.children}
		</View>
	)
})
